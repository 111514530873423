import { makeStyles, useTheme } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import React, { ChangeEventHandler } from 'react'

export const stringIsValid = (obj: FieldData, val: string) => val && val.length >= obj.minLength && val.length <= obj.maxLength
export const emailIsValid = (obj: FieldData, val: string) => stringIsValid(obj, val) && val.indexOf('@') > 0 && val.indexOf('.') > 0

export interface FieldData {
  name: string
  value: string
  hasErrors: boolean
  helperText: string
  errorMessage: string
  minLength: number
  maxLength: number
}

interface ContactFieldProps {
  id: string,
  label: string,
  value: string,
  type?: string,
  helperText?: string
  multiline?: boolean
  rows?: number
  error?: boolean
  className?: any
  required?: boolean
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

export const ContactField = (props: ContactFieldProps) => {
  const theme = useTheme()

  const fontFamily = ['arial', 'sans-serif'].join(',')
  const fieldStyles = makeStyles({
      root: {
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: 300,
        },
      },
      helperText: {
        color: 'red',
        fontFamily: fontFamily,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: "normal",
      },
      inputText: {
        color: theme.palette.primary.dark,
        fontFamily: fontFamily,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "normal",
      },
      labelText: {
        color: theme.palette.primary.dark,
        fontFamily: fontFamily,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "normal"
    }
  })

  const styles = fieldStyles()

  return <TextField className={styles.root} variant='outlined' type={props.type || 'text'} required {...props}
                    FormHelperTextProps={{

                      color: theme.palette.error.main,
                      classes: { root: styles.helperText },
                    }}
                    InputLabelProps={{
                      color: 'primary',
                      classes: { root: styles.labelText },
                    }}
                    InputProps={{
                      color: 'primary',
                      classes: { root: styles.inputText },
                    }}
  />
}
