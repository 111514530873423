import React, { useState } from 'react'
import { Button, Checkbox, createStyles, FormControlLabel, Grid, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ContactField, emailIsValid, FieldData, stringIsValid } from './ContactField'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    advisory: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      marginTop: 20,
      textAlign: 'justify'
    },
    charCount: {
      textAlign: 'right',
    },
    contactForm: {
      margin: 'auto',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.up('md')]: {
        width: '60%',
      },
      fontWeight: 'bold',
      paddingTop: 50,
      paddingBottom: 30,
      border: '5px solid darkgrey',
    },
    contactFormHeightSm: {
      height: '41rem',
    },
    contactFormHeightMd: {
      height: '36rem',
    },
    contactFormHeightMdUp: {
      height: '31rem',
    },
    fieldContainer: {
      paddingTop: 5,
      paddingBottom: 10,
      width: '90%',
      margin: 'auto',
    },
    // visible honeypot for testing
    optInHpVisible: {
      opacity: 1,
      width: 10,
      color: 'black'
    },
    // honeypot
    optInHp: {
      borderRadius: 1,
      display: 'none',
      overflow: 'hidden',
      width: 1,
      height: 1,
      opacity: 0,
      visibility: 'hidden',
    },
    optInCheckboxContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    optInEmailLabel: {
      color: theme.palette.primary.dark,
    },
    optInEmailCheckbox: {
      color: theme.palette.primary.dark,
      fontSize: theme.typography.pxToRem(48),
    },
    textAreaContainer: {
      height: '6rem',
    },
    submitButtonOptInContainer: {
      paddingTop: '10rem',
      width: '100%',
      margin: 'auto',
    },
    submitButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    submitButtonText: {
      color: theme.palette.text.secondary,
      fontFamily: ['bree', 'sans-serif'].join(','),
      fontSize: theme.typography.pxToRem(24),
    },
    textField: {
      marginTop: 10,
      width: '100%',
      height: '6em',
    },
  })
})

const firstNameData: FieldData = {
  name: 'firstName',
  value: '',
  minLength: 1,
  maxLength: 32,
  helperText: '',
  hasErrors: false,
  errorMessage: 'First name is required and must be no more than 32 characters',
}

const lastNameData: FieldData = {
  name: 'lastName',
  value: '',
  minLength: 2,
  maxLength: 64,
  helperText: '',
  hasErrors: false,
  errorMessage: 'Last name is required and must be between 2 and 64 characters',
}

const emailData: FieldData = {
  name: 'email',
  value: ``,
  minLength: 6,
  maxLength: 128,
  helperText: '',
  hasErrors: false,
  errorMessage: 'Email is required and must be a valid email format',
}

const commentData: FieldData = {
  name: 'comment',
  value: '',
  minLength: 10,
  maxLength: 512,
  helperText: '',
  hasErrors: false,
  errorMessage: 'Comment is required and must be between 10 and 512 characters',
}

interface ContactFormProps {
  saveContact: Function
}

const ContactForm = ({ saveContact }: ContactFormProps) => {
  const [firstNameField, setFirstNameField] = useState(firstNameData)
  const [lastNameField, setLastNameField] = useState(lastNameData)
  const [emailField, setEmailField] = useState(emailData)
  const [commentField, setCommentField] = useState(commentData)
  const [emailOptInField, setEmailOptInField] = useState(false)
  const [honeyPotField, setHoneyPotField] = useState(false)
  const [submittable, setSubmittable] = useState(false)

  const classes = useStyles()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isValid()) {
      const payload = {
        firstName: firstNameField.value,
        lastName: lastNameField.value,
        email: emailField.value,
        comment: commentField.value,
        emailOptIn: emailOptInField,
        contactedOn: new Date(),
      }

      if (!honeyPotField) {
        saveContact(payload)
      } else {
        // TODO: Add logging to service here
        saveContact(null)
      }
    }
  }

  const handleChange = (e) => {
    const { value, id } = e.target
    const changes = { value, hasErrors: false }

    switch (id) {
      case 'emailOptIn':
        setEmailOptInField(!emailOptInField)
        break
      case 'firstName':
        changes.hasErrors = !stringIsValid(firstNameField, value)
        setFirstNameField({ ...firstNameField, ...changes })
        break
      case 'lastName':
        changes.hasErrors = !stringIsValid(lastNameField, value)
        setLastNameField({ ...lastNameField, ...changes })
        break
      case 'email':
        changes.hasErrors = !emailIsValid(emailField, value)
        setEmailField({ ...emailField, ...changes })
        break
      case 'comment':
        changes.hasErrors = !stringIsValid(commentField, value)
        setCommentField({ ...commentField, ...changes })
        break
      case 'optInHp':
        setHoneyPotField(!honeyPotField)
        break
      default:
        console.error('error', e)
    }

    setSubmittable(stringIsValid(firstNameField, firstNameField.value) &&
      stringIsValid(lastNameField, lastNameField.value) &&
      emailIsValid(emailField, emailField.value) &&
      stringIsValid(commentField, commentField.value))
  }

  const isValid = (): boolean => !(firstNameField.hasErrors || lastNameField.hasErrors || emailField.hasErrors || commentField.hasErrors)

  const helperText = (field: FieldData) => field.hasErrors ? field.errorMessage : field.helperText

  const CharacterCount = () => (commentField.value ?
      <Typography variant='body2' className={classes.charCount}>Characters remaining: {commentField.maxLength - commentField.value?.length}</Typography>
      : null
  )

  return (
    <form className={classes.contactForm} noValidate autoComplete='off' onSubmit={handleSubmit}>
      <Grid container className={classes.fieldContainer}>
        <Grid item sm={12} className={classes.textField}>
          <ContactField id='firstName' className={classes.textField} label='First Name' value={firstNameField.value}
                        helperText={helperText(firstNameField)} onChange={handleChange} />
        </Grid>
        <Grid item sm={12} className={classes.textField}>
          <ContactField id='lastName' className={classes.textField} label='Last Name' value={lastNameField.value}
                        helperText={helperText(lastNameField)} onChange={handleChange} />
        </Grid>
        <Grid item sm={12} className={classes.textField}>
          <ContactField id='email' className={classes.textField} label='Email' value={emailField.value}
                        helperText={helperText(emailField)} onChange={handleChange} />
        </Grid>
        <Grid item sm={12} className={classes.textField}>
          <div className={classes.textAreaContainer}>
            <CharacterCount />
            <ContactField id='comment' className={classes.textField}
                          multiline rows={5} label='Comment' value={commentField.value}
                          helperText={helperText(commentField)} onChange={handleChange} />
          </div>
        </Grid>
        <Grid container className={classes.submitButtonOptInContainer}>

          <Grid item sm={12} className={classes.submitButtonContainer}>
            <Button type='submit' variant='contained' color='primary' disabled={!submittable}>
              <Typography variant='h5' className={classes.submitButtonText}>
                Contact Us
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.optInCheckboxContainer}>
          <FormControlLabel
            className={classes.optInEmailLabel}
            control={
              <Checkbox
                id='emailOptIn'
                onChange={handleChange}
                color='primary'
                className={classes.optInEmailCheckbox}
                value={emailOptInField}
              />
            }
            checked={emailOptInField}
            label={<Typography variant='body2' className={classes.optInEmailLabel}>Sign me up for the newsletter</Typography>}
          />
          <Checkbox
            id='optInHp'
            onChange={handleChange}
            className={classes.optInHp}
            value={honeyPotField}
          />
        </Grid>
        <Grid item sm={12}>
          <Typography className={classes.advisory}>
            NOTE: By submitting the contact form, you consent to us saving the information in the form
            to improve our service and to contact you in response to the submission. If you elect
            to sign up for our newsletter, we will use your contact information for that purpose.
          </Typography>
        </Grid>
      </Grid>

    </form>
  )
}

export default ContactForm
