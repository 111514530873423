import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/SEO";
import NarrativeRow, { ImagePosition } from "../components/NarrativeRow";
import ContactForm from "../components/contact/ContactForm";
import { navigate } from "gatsby";
import { JSON_MIMETYPE } from "../constants";
import { createStyles, Grid, Snackbar, Theme, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressBlock: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.dark,
      margin: 'auto',
      width: "29rem"

    },
    addressLine: {
      color: theme.palette.primary.dark
    },
    advisory: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      paddingTop: 15,
      margin: 'auto',
      textAlign: 'justify',
      width: '60%'
    },
    mailIcon: {
      color: theme.palette.secondary.dark,
      paddingLeft: 10,
      size: "medium",
      verticalAlign: "middle"
    }
  })
);

const bookStackImage = (
  <StaticImage src="../images/bookStack.jpg" alt="stack of books" width={200} />
);

const boyHikerOnBook = (
  <StaticImage
    src="../images/boy_hiker_on_book_pages.jpg"
    alt="boy hiker on book pages"
    width={160}
  />
);

interface WebContact {
  firstName: string;
  lastName: string;
  email: string;
  comment: string;
  emailOptIn: boolean;
  contactedOn: Date;
}

interface WebContactResponse {
  statusCode: number;
  body: any | null;
  error: string | null;
}

const saveWebContact = async (webContact: WebContact): Promise<WebContactResponse> => {
  const response = await fetch("/api/save-web-contact", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": JSON_MIMETYPE
    },
    redirect: "error",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(webContact) // body data type must match "Content-Type" header
  });

  return {
    statusCode: response.status,
    body: response.status < 300 ? await response.json() : null,
    error: response.status >= 300 ? response.statusText : null
  };
};

const ContactPage = () => {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [webContactResponse, setWebContactResponse] = useState<WebContactResponse>(null);

  const classes = useStyles();

  const saveContact = (contact: WebContact | null) => {
    // a null contact means a honey pot trap was sprung
    if (contact) {
      saveWebContact(contact).then(handleSanityResponse);
    } else {
      // make the page act the same as if a real submission happened
      handleSanityResponse({ statusCode: 400, body: null, error: null });
    }
  };

  const handleSanityResponse = (result: WebContactResponse) => {
    setWebContactResponse(result);
    setSnackBarOpen(true);
  };

  const handleSnackBarClose = async () => {
    setSnackBarOpen(false);
    await navigate("/");
  };

  const ErrorAlert = () => (
    <Alert elevation={6} variant="filled" onClose={handleSnackBarClose} onClick={handleSnackBarClose} severity="error">
      <AlertTitle>Error</AlertTitle>
      We're sorry. There was an error sending your message.
    </Alert>
  );

  const SuccessAlert = () => (
    <Alert elevation={6} variant="filled" onClose={handleSnackBarClose} onClick={handleSnackBarClose} severity="success">
      <AlertTitle>Success</AlertTitle>
      Your message was sent. We'll be in touch soon!
    </Alert>
  );

  return (
    <div>
      <SEO title="Contact" />

      <NarrativeRow image={bookStackImage} imagePosition={ImagePosition.LEFT}>
        Have a suggestion, idea or constructive criticism?
        We'd love to hear from you. If you want to send us some old-fashion
        snail mail, please use the address below:
      </NarrativeRow>
      <div className={classes.addressBlock}>
        <StaticImage src="../images/otkp_address.jpg"
                     alt="address: 6209 Mid Rivers Mall Drive, Number 261, St. Peters, MO 63304"
                     width={400} />
      </div>
      <Typography className={classes.advisory}>
        Please do NOT mail us unsolicited manuscripts. If you'd like us to consider your
        book for publication, please briefly describe your book in the contact form. If we
        think it's a fit, we'll work with you to get it published through our company.
      </Typography>

      <NarrativeRow image={boyHikerOnBook} imagePosition={ImagePosition.RIGHT}>
        We'd love to hear from you. Please complete the following form, and
        our team will get to work on it.
      </NarrativeRow>

      <ContactForm saveContact={saveContact} />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackBarOpen}
        onClose={() => handleSnackBarClose()}
        onClick={() => handleSnackBarClose()}
        key={"done"}
        autoHideDuration={5000}
      >
        {webContactResponse && webContactResponse.statusCode === 201 ? <SuccessAlert /> : <ErrorAlert />}
      </Snackbar>

    </div>
  );
};

export default ContactPage;
